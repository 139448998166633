import 'scroll-behavior-polyfill';

export default {
  finalize() {
    /** Remove blurry placeholder from images - see https://www.industrialempathy.com/posts/image-optimizations/ **/
    document.body.addEventListener('load', (e) => {
        if (e.target.tagName != 'IMG') {
          return;
        }
        // Remove the blurry placeholder.
        e.target.style.backgroundImage = 'none';
      },
      /* capture */ true)

    /** Handle scrolling with debounce */
    let ticking = false;

    function update() {
      // reset the tick so we can
      // capture the next onScroll
      ticking = false;
      if (window.scrollY > 80) {
        document.body.classList.add('scrolled-down');
      } else {
        document.body.classList.remove('scrolled-down');
      }
    }

    function onScroll() {
      if (!ticking) {
        requestAnimationFrame(update);
      }
      ticking = true;
    }

    window.addEventListener('scroll', onScroll, false);
    /** end scrolling handling **/

    if (document.getElementsByClassName('swiper-container').length > 0) {
      // init slideshows
      /* global Swiper */
      new Swiper('.swiper-container', {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        a11y: {
          prevSlideMessage: 'Previous slide',
          nextSlideMessage: 'Next slide',
        },
        autoplay: {
          delay: 5000,
        },
      });
    }
  },
};
