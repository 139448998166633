//import flatpickr from 'flatpickr';
//import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';

export default {
  finalize() {
    /* global flatpickr, rangePlugin, locale */
    const datePicker = flatpickr('#checkin', {
      mode: 'range',
      minDate: 'today',
      locale: locale.current,
      formatDate: dateObj => dateObj.toLocaleDateString(locale.current),
      'plugins': [new rangePlugin({input: '#checkout'})],
    });

    window.booking = window.bookingWidget || {};
    window.booking.submit = function () {
      const hotelCode = parseInt(document.getElementById('hotel_code').value);
      const checkin = datePicker.selectedDates[0].yyyymmdd();
      const checkout = datePicker.selectedDates[1].yyyymmdd();
      const adults = parseInt(document.getElementById('adults').value);
      const children = parseInt(document.getElementById('children').value);
      const childrenage = document.getElementsByName('childrenage[]');
      let ages = '';
      if (childrenage.length > 0) {
        ages = '&ages=';
        for (let i = 0; i < childrenage.length; i++) {
          ages += childrenage[i].value + ',';
        }
        ages = ages.slice(0, -1);
      }

      const base_ermes_url = hotelCode > 0 ? locale.ermes_base_url.replace('/#', '/#/hotel/' + hotelCode).replace('/?', '/rooms/?') : locale.ermes_base_url;

      window.open(base_ermes_url + 'arrival=' + checkin + '&departure=' + checkout + '&adults=' + adults + '&childs=' + children + ages, '_blank');
    }
  },
};
