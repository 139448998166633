// core version + navigation, pagination modules:
// import Swiper, {A11y, Autoplay, EffectFade, Lazy, Navigation, Pagination} from 'swiper';
// import flatpickr from 'flatpickr';
// import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';

export default {
  finalize() {
    // breakpoint where swiper will be destroyed
    // and switches to a dual-column layout
    const breakpoint = window.matchMedia('(min-width:1024px)');

    /* global Swiper */
    // keep track of swiper instances to destroy later
    const enableSwiper = function () {
      if (window.gallery !== undefined) {
        window.gallery.destroy(true, true);
      }
      if (breakpoint.matches) {
        // init slideshows
        console.log('Enabling Swiper');
        // Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay, A11y]);
        window.gallery = new Swiper('.swiper-accommodation-container', {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
          a11y: {
            prevSlideMessage: 'Previous slide',
            nextSlideMessage: 'Next slide',
          },
          autoplay: {
            delay: 5000,
          },
          lazyLoading: true,
          lazy: {
            loadPrevNext: true,
          },
          resizeObserver: true,
        });
      }
    };

    const breakpointChecker = function () {
      enableSwiper();
      if (breakpoint.matches) {
        initPicker();
      } else {
        initPickerModal();
      }
    }

    breakpoint.addEventListener('change', breakpointChecker);
    breakpointChecker(breakpoint);

    window.addEventListener('resize', breakpointChecker);

    /* global flatpickr, rangePlugin, locale */
    let bookingDates;

    function initPicker() {
      bookingDates = flatpickr('#checkin', {
        mode: 'range',
        minDate: 'today',
        locale: locale.current,
        formatDate: dateObj => dateObj.toLocaleDateString(locale.current),
        'plugins': [new rangePlugin({input: '#checkout'})],
      });
    }

    function initPickerModal() {
      bookingDates = flatpickr('#checkin-mobile', {
        mode: 'range',
        minDate: 'today',
        locale: locale.current,
        formatDate: dateObj => dateObj.toLocaleDateString(locale.current),
        'plugins': [new rangePlugin({input: '#checkout-mobile'})],
      });
    }

    window.bookingWidget = window.bookingWidget || {};
    window.bookingWidget.submit = function () {
      const checkin = bookingDates.selectedDates[0].yyyymmdd();
      const checkout = bookingDates.selectedDates[1].yyyymmdd();
      const adults = parseInt(document.getElementById('adults').value);
      const children = parseInt(document.getElementById('children').value);
      const childrenage = document.getElementsByName('childrenage[]');
      let ages = '';
      if (childrenage.length > 0) {
        ages = '&ages=';
        for (let i = 0; i < childrenage.length; i++) {
          ages += childrenage[i].value + ',';
        }
        ages = ages.slice(0, -1);
      }

      // build link, @see https://ermeshotels.gitbook.io/ermeshotels/booking-engine/link
      window.open(locale.ermes_base_url + 'arrival=' + checkin + '&departure=' + checkout + '&adults=' + adults + '&childs=' + children + ages, '_blank');

      // https://book.ermeshotels.com/#/hotel/1311/channel/138/language/2/rooms?arrival=20180101&departure=20180102&adults=2,2&childs=0,3
    }
  },
};
