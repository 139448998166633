// core version + navigation, pagination modules:
// import Swiper, {A11y, Autoplay, EffectFade, Navigation, Pagination} from 'swiper';

export default {
  finalize() {
    // configure Swiper to use modules
    /* global Swiper */
    new Swiper('.swiper-home-container', {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
      },
      autoplay: {
        delay: 5000,
      },
    });

    // breakpoint where swiper will be destroyed
    // and switches to a dual-column layout
    const breakpoint = window.matchMedia('(min-width:1024px)');

    // keep track of swiper instances to destroy later
    const enableSwiper = function () {
      if (window.gallery !== undefined) {
        window.gallery.destroy(true, true);
      }
      if (breakpoint.matches) {
        // init slideshows
        console.log('Enabling Swiper');
        // Swiper.use([Navigation, Pagination, EffectFade, Lazy, Autoplay, A11y]);
        window.gallery = new Swiper('.swiper-accommodation-container', {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
          a11y: {
            prevSlideMessage: 'Previous slide',
            nextSlideMessage: 'Next slide',
          },
          autoplay: {
            delay: 5000,
          },
          lazyLoading: true,
          lazy: {
            loadPrevNext: true,
          },
          resizeObserver: true,
        });
      }
    };

    const breakpointChecker = function () {
      enableSwiper();
    }

    breakpoint.addEventListener('change', breakpointChecker);
    breakpointChecker(breakpoint);

    window.addEventListener('resize', breakpointChecker);
  },
};
